import React,{useState} from "react";

import "./OrderPage.css";

import OrderItem from "../OrderItem";
import AddOrderForm from "../AddOrderForm";

function OrderPage() {
  const [activeCategory, setActiveCategory] = useState(0);

  const ordersCategories = ['Running orders |', ' Bill requests |', ' Past orders |', 'Add Order'];

  const handleLinkClick = (index) => {
        
    setActiveCategory(index);

  };

  const handleOrderAdded = () => {
    setActiveCategory(0); // Switch back to running orders
  };

  return (
    <div className="orders" id="orders">
      {ordersCategories.map((orderCategory, index) => (
        <a
          href="#orders"
          key={orderCategory}
          onClick={() => handleLinkClick(index)}
          className={index === activeCategory ? "active" : "inactive"}
        >
          {orderCategory}
        </a>
      ))}
      {activeCategory === 3 ? (
        <AddOrderForm onOrderAdded={handleOrderAdded} />
      ) : (
        <OrderItem category={activeCategory} />
      )}
    </div>
  );
}
  
  export default OrderPage;