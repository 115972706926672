import React from "react";

import "./MenuPage.css";

import MenuCategories from "../MenuCategories";

function MenuPage() {
    return (
        <div className="menu">
            <MenuCategories />
        </div>
    );
  }
  
  export default MenuPage;