import axios from "axios";

/* base url to make requests to biblophile actions api. Turn it to spadeleaf actions later */
const instance = axios.create({
    baseURL: "https://spadeleaf.com/",
});

// Add a request interceptor to include the Authorization header
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add an interceptor to handle token expiration
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem("refreshToken");
            try {
                const { data } = await axios.post(
                    "spadeleaf-api.php?action=refreshToken",
                    { refreshToken }
                );
                if (data.message === 1) {
                    localStorage.setItem("accessToken", data.accessToken);
                    instance.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;
                    originalRequest.headers["Authorization"] = `Bearer ${data.accessToken}`;
                    return instance(originalRequest);
                }
            } catch (err) {
                console.error("Refresh token expired or invalid. Please log in again.");
                // Optionally, redirect to the login page or show a message
                // window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default instance;