import React, { useState, useEffect, useContext } from "react";
import axios from "../services/axios";
import "./MenuCategories.css";
import MenuItem from "./MenuItem";
import CategoryInsertModal from "./CategoryInsertModal";

function MenuCategories() {
    const [foodCategories, setFoodCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editCategoryIndex, setEditCategoryIndex] = useState(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleLinkClick = (index) => {
        setActiveCategory(index);
    };

    const handleEditCategory = (index) => {
        setEditCategoryIndex(index);
    };

    const handleHideCategory = (categoryId) => {
        async function hideCategory() {
            try {
                const response = await axios.post("spadeleaf-api.php?action=hideCategory", {
                    categoryId: categoryId,
                  });
                if (response.data.message === 1)
                {
                    fetchData();
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);
                }
              } catch (error) {
                console.log(error);
              }
        }
        hideCategory();
    };

    const handleDeleteCategory = async (categoryId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this category?");
        
        if (isConfirmed) {
            try {
                const response = await axios.post('spadeleaf-api.php?action=deleteFoodCategory', { 
                    categoryId: categoryId,
                });
                if (response.data.message === 1)
                {
                    fetchData();
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);
                }
            } catch (error) {
                console.error('Error deleting category:', error);
            }
        } else {
            console.log("Item deletion canceled.");
        }
    };

    const handleCategoryChange = (event, index) => {
        const newCategories = [...foodCategories];
        newCategories[index].category = event.target.value;
        setFoodCategories(newCategories);
    };

    const handleCategoryBlur = async (index) => {
        try {
            const categoryId = foodCategories[index].categoryId;
            const category = foodCategories[index].category;
            const response = await axios.post('spadeleaf-api.php?action=updateFoodCategory', {
                categoryId: categoryId,
                category: category 
            });
            if (response.data.message === 1)
            {
                fetchData();
            }
            else
            {
                alert(response.data.message);
                console.log(response);
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
        setEditCategoryIndex(null);
    };

    async function fetchData() {
        try {
            const response = await axios(`spadeleaf-api.php?action=getFoodCategory`);
            setFoodCategories(response.data);
        } catch (error) {
            console.error('Error fetching food categories:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const [pressTimer, setPressTimer] = useState(null);

    const handleLongPressStart = (index) => {
        const longPressDuration = 700; // Set the duration for long press (700ms)
        
        const timer = setTimeout(() => {
            handleEditCategory(index);
        }, longPressDuration);

        setPressTimer(timer);
    };

    const handleLongPressEnd = () => {
        if (pressTimer) {
            clearTimeout(pressTimer);
            setPressTimer(null);
        }
    };

    if (foodCategories.length === 0 || activeCategory >= foodCategories.length) {
        return null;
    }

    return (
        <div className="menuCategories" id="menuCategories">
            <CategoryInsertModal isOpen={isModalOpen} onClose={closeModal} />

            {foodCategories.map((foodCategory, index) => (
                <span key={index} className="categoryWrapper">
                    <a
                        href="#menuCategories"
                        onClick={() => handleLinkClick(index)}
                        onTouchStart={() => handleLongPressStart(index)} // For mobile devices
                        onMouseDown={() => handleLongPressStart(index)}  // For desktop
                        onTouchEnd={handleLongPressEnd}  // For mobile devices
                        onMouseUp={handleLongPressEnd}   // For desktop
                        className={index === activeCategory ? "active" : "inactive"}
                    >
                        {editCategoryIndex === index ? (
                            <input
                                type="text"
                                value={foodCategory.category}
                                onChange={(event) => handleCategoryChange(event, index)}
                                onBlur={() => handleCategoryBlur(index)}
                                autoFocus
                            />
                        ) : (
                            foodCategory.category
                        )}
                    </a>
                    {" | "}
                </span>
            ))}

            <button className="addCategoryButton" onClick={openModal}>Add category</button>

            <div className="actionButtons">
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={foodCategories[activeCategory].isHidden !== '1'}
                        onChange={() => handleHideCategory(foodCategories[activeCategory].categoryId)}
                    />
                    <span className="slider"></span>
                </label>
                <button
                    className="deleteButton"
                    onClick={() => handleDeleteCategory(foodCategories[activeCategory].categoryId)}
                >
                    Delete
                </button>
            </div>
            
            <div className={`${foodCategories[activeCategory].isHidden === '1' ? 'hidden' : ''}`}>
                <MenuItem category={foodCategories[activeCategory].categoryId} />
            </div>
        </div>
    );
}

export default MenuCategories;
