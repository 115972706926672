import React, { useState, useEffect, useContext } from "react";
import axios from "../services/axios";
import ReactPDF, { PDFViewer, Document, Page, View, Text, StyleSheet, PDFDownloadLink, Font } from '@react-pdf/renderer';
import "./OrderItem.css";

// Register the font
Font.register({
  family: 'NotoSans',
  src: 'https://fonts.gstatic.com/s/notosans/v10/o-0IIpQlx3QUlC5A4PNb4g.ttf',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#FFFFFF',
    fontSize: 12,
    fontFamily: 'NotoSans',
  },
  header: {
    textAlign: 'center',
    marginBottom: 10,
  },
  restaurantName: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  subHeader: {
    fontSize: 10,
    color: '#888',
    marginBottom: 5,
  },
  divider: {
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    marginVertical: 10,
  },
  section: {
    marginVertical: 10,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  customerInfo: {
    fontSize: 12,
    marginBottom: 5,
  },
  table: {
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 12,
    textAlign: 'left',
    flex: 1,
  },
  cell: {
    fontSize: 12,
    textAlign: 'left',
    flex: 1,
  },
  totalRow: {
    flexDirection: "row",
    paddingVertical: 10,
    borderTopWidth: 2,
    borderTopColor: '#000',
    marginTop: 10,
  },
  totalLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'left',
  },
  totalAmount: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'right',
    flex: 1,
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
  },
  footerText: {
    fontSize: 10,
    color: 'gray',
    marginBottom: 4,
  },
});

function OrderItem({ category }) {

  const resName = localStorage.getItem("resName");
  const resGST = localStorage.getItem("resGST");

  const [orderItems, setOrderItems] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedTableNumber, setSelectedTableNumber] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); 
  const [refreshKey, setRefreshKey] = useState(0);

  const updateOrderStatus = (status, orderId) => {
    async function updateData() {
      try {
        const response = await axios.post("spadeleaf-api.php?action=updateOrderStatus", {
          status: status,
          orderId: orderId,
        });
        if (response.data.message === 1) {
          setRefreshKey((prevKey) => prevKey + 1);
        } else {
          alert(response.data.message);
          console.log(response);
        }
      } catch (error) {
        console.error("Error updating order status:", error);
      }
    }
    updateData();
  };

  const fetchOrderStatus = (orderItem) => {
    if (orderItem.OrderStatus === '0' && (orderItem.PaymentStatus === '0' || orderItem.PaymentStatus === '1')) {
      return (
        <>
          <p>New order</p>
          {orderItem.PaymentStatus === '1' ? <p><b>Payment completed</b></p> : <p><b>Payment incomplete</b></p>}
          <div className="orderStatusButtons">
            <button className="greenButton" onClick={() => updateOrderStatus('accepted', orderItem.OrderId)}>Accept</button>
            <button className="redButton" onClick={() => updateOrderStatus('rejected', orderItem.OrderId)}>Reject</button>
          </div>
        </>
      );
    } else if (orderItem.OrderStatus === '1' && (orderItem.PaymentStatus === '0' || orderItem.PaymentStatus === '1')) {
      return (
        <>
          <p>Preparing...</p>
          {orderItem.PaymentStatus === '1' ? <p><b>Payment completed</b></p> : <p><b>Payment incomplete</b></p>}
          <div className="orderStatusButtons">
            <button className="greenButton" onClick={() => updateOrderStatus('completed', orderItem.OrderId)}>Completed</button>
          </div>
        </>
      );
    } else if (orderItem.OrderStatus === '2' && orderItem.PaymentStatus === '0') {
      return (
        <>
          <div className="orderStatusButtons">
            <PDFDownloadLink document={generateBillPdf(orderItem)} fileName={`bill_${orderItem.OrderId}.pdf`}>
              <button className="billButton">Generate Bill</button>
            </PDFDownloadLink>
            <button className="greenButton" onClick={() => updateOrderStatus('paid', orderItem.OrderId)}>Payment completed</button>
          </div>
        </>
      );
    } else if (orderItem.OrderStatus === '2' && orderItem.PaymentStatus === '1') {
      return (
        <>
          <div className="orderStatusButtons">
            <PDFDownloadLink document={generateBillPdf(orderItem)} fileName={`bill_${orderItem.OrderId}.pdf`}>
              <button className="billButton">Generate Bill</button>
            </PDFDownloadLink>
          </div>
        </>
      );
    } else {
      return <p>Rejected order</p>;
    }
  };

  // Function to update order selection for merging
  const handleOrderSelection = (orderId, tableNumber) => {
    setSelectedOrders((prevSelected) => {
      const updatedSelected = prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId];
  
      // Clear selectedTableNumber if no orders are selected
      if (updatedSelected.length === 0) {
        setSelectedTableNumber(null);
      } else {
        // Set the selected table number only if it matches
        setSelectedTableNumber(tableNumber);
      }
  
      return updatedSelected;
    });
  };

  // Function to merge selected orders
  const mergeSelectedOrders = () => {
    const mergedOrders = orderItems.filter((order) =>
        selectedOrders.includes(order.OrderId) && order.OrderStatus === "2" && order.TableNumber !== "0"
    );

    const mergedItems = mergedOrders.flatMap((order) => order.OrderItems);

    const mergedServiceCharge = mergedOrders.length > 0 ? mergedOrders[0].RestaurantServiceCharge : 0;
    const mergedTax = mergedOrders.length > 0 ? mergedOrders[0].RestaurantTax : 0;
    const mergedTotal = mergedOrders.reduce((acc, order) => acc + parseFloat(order.TotalPrice), 0);

    return { items: mergedItems, serviceCharge: mergedServiceCharge, tax: mergedTax, total: mergedTotal };
};

  // Generate merged bill PDF
  const generateMergedBillPdf = (mergedOrder) => {
    const serviceChargeAmount = (mergedOrder.serviceCharge / 100) * mergedOrder.total;
    const taxAmount = (mergedOrder.tax / 100) * mergedOrder.total;
    const finalTotal = mergedOrder.total;

    const orderDoc = (
      <Document>
        <Page size="A5" style={styles.page}>
          {/* Merged Bill Header */}
          <View style={styles.header}>
            <Text style={styles.restaurantName}>{resName}</Text>
            <Text style={styles.subHeader}>GSTIN: {resGST}</Text>
            <Text style={styles.subHeader}>Bill generated at: {new Date().toLocaleString()}</Text>
          </View>

          {/* Divider Line */}
          <View style={styles.divider} />

          {/* Merged Items */}
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Order Summary</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Item</Text>
                <Text style={styles.tableHeader}>Qty</Text>
                <Text style={styles.tableHeader}>Price (₹)</Text>
              </View>
              {mergedOrder.items.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.cell}>{item.MenuItem.ItemName}</Text>
                  <Text style={styles.cell}>x{item.Quantity}</Text>
                  <Text style={styles.cell}>{item.Amount}</Text>
                </View>
              ))}
            </View>
          </View>
          {/* Merged Service Charge and Tax */}
          <View style={styles.table}>
            {mergedOrder.serviceCharge && 
              <View style={styles.tableRow}>
                <Text style={styles.cell}>Service Charge</Text>
                <Text style={styles.cell}>{mergedOrder.serviceCharge || 0}%</Text>
                <Text style={styles.cell}>{serviceChargeAmount.toFixed(2)}</Text>
            </View>
            }
            {mergedOrder.tax && 
            <View style={styles.tableRow}>
              <Text style={styles.cell}>Tax</Text>
              <Text style={styles.cell}>{mergedOrder.tax || 0}%</Text>
              <Text style={styles.cell}>{taxAmount.toFixed(2)}</Text>
            </View>
            }
              <View style={styles.totalRow}>
                  <Text style={styles.totalLabel}>Total Amount</Text>
                  <Text style={styles.totalAmount}>₹{finalTotal.toFixed(2)}</Text>
              </View>
          </View>

          {/* Divider Line */}
          <View style={styles.divider} />

          <View style={styles.footer}>
            <Text style={styles.footerText}>Thank you for visiting {resName}!</Text>
            <Text style={styles.footerText}>Powered by Spadeleaf</Text>
          </View>
        </Page>
      </Document>
    );
    return orderDoc;
  };

  const generateBillPdf = (orderItem) => {
    const totalServiceCharge = orderItem.RestaurantServiceCharge
      ? orderItem.OrderItems.reduce(
          (acc, item) => acc + item.Amount * (orderItem.RestaurantServiceCharge / 100),
          0
        ).toFixed(2)
      : null;
  
    const totalTaxes = orderItem.RestaurantTax
      ? orderItem.OrderItems.reduce(
          (acc, item) => acc + item.Amount * (orderItem.RestaurantTax / 100),
          0
        ).toFixed(2)
      : null;
  
    const finalTotal = parseFloat(orderItem.TotalPrice);
  
    const orderDoc = (
      <Document>
        <Page size="A5" style={styles.page}>
          {/* Bill Header */}
          <View style={styles.header}>
            <Text style={styles.restaurantName}>{resName}</Text>
            <Text style={styles.subHeader}>GSTIN: {resGST}</Text>
            <Text style={styles.subHeader}>Bill No: {orderItem.OrderId}</Text>
            <Text style={styles.subHeader}>Date: {new Date(orderItem.OrderDate).toLocaleDateString()}</Text>
          </View>
  
          {/* Divider Line */}
          <View style={styles.divider} />
  
          {/* Customer Info */}
          <View style={styles.section}>
            <Text style={styles.customerInfo}>Customer Name: {orderItem.CustomerName}</Text>
            {orderItem.TableNumber !== "0" && (
              <Text style={styles.customerInfo}>Table Number: {orderItem.TableNumber}</Text>
            )}
          </View>
  
          {/* Divider Line */}
          <View style={styles.divider} />
  
          {/* Order Details Section */}
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Order Summary</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Item</Text>
                <Text style={styles.tableHeader}>Qty</Text>
                <Text style={styles.tableHeader}>Price (₹)</Text>
              </View>
  
              {orderItem.OrderItems.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.cell}>{item.MenuItem.ItemName}</Text>
                  <Text style={styles.cell}>x{item.Quantity}</Text>
                  <Text style={styles.cell}>{item.Amount}</Text>
                </View>
              ))}
  
              {orderItem.RestaurantServiceCharge && (
                <View style={styles.tableRow}>
                  <Text style={styles.cell}>Service Charge</Text>
                  <Text style={styles.cell}>{orderItem.RestaurantServiceCharge}%</Text>
                  <Text style={styles.cell}>{totalServiceCharge}</Text>
                </View>
              )}
  
              {orderItem.RestaurantTax && (
                <View style={styles.tableRow}>
                  <Text style={styles.cell}>Tax</Text>
                  <Text style={styles.cell}>{orderItem.RestaurantTax}%</Text>
                  <Text style={styles.cell}>{totalTaxes}</Text>
                </View>
              )}
  
              {/* Total Amount */}
              <View style={styles.totalRow}>
                <Text style={styles.totalLabel}>Total Amount</Text>
                <Text style={styles.totalAmount}>
                  ₹{finalTotal}
                </Text>
              </View>
            </View>
          </View>
  
          {/* Divider Line */}
          <View style={styles.divider} />
  
          {/* Footer */}
          <View style={styles.footer}>
            <Text style={styles.footerText}>Thank you for visiting {resName}!</Text>
            <Text style={styles.footerText}>Powered by Spadeleaf</Text>
          </View>
        </Page>
      </Document>
    );
  
    return orderDoc;
  };

  //this snippet fetching orders only for a particular page n
  // useEffect(() => {
  //   async function fetchOrders(page) {
  //     try {
  //       const response = await axios.get(`spadeleaf-api.php?action=getOrders&resId=${resId}&status=${category}&page=${page}`);
  //       const fetchedOrders = response.data;
  //       if (fetchedOrders.length > 0) {
  //         // setOrderItems((prevOrders) => [...prevOrders, ...fetchedOrders]); // Append new orders
  //         setOrderItems(prevOrders => {
  //           const prevOrderIds = new Set(prevOrders.map(order => order.OrderId));
  //           const uniqueOrders = fetchedOrders.filter(order => !prevOrderIds.has(order.OrderId));
  //           return [...prevOrders, ...uniqueOrders];
  //       });
  //         setHasMore(true);
  //       } else {
  //         setHasMore(false);
  //       }
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       setError("Failed to fetch food items. Please try again later.");
  //       setLoading(false);
  //     }
  //   }
  //   fetchOrders(page);

  //   const interval = setInterval(() => fetchOrders(page), 60000);

  //   // Clear interval on component unmount to prevent memory leaks
  //   return () => clearInterval(interval);

  // }, [category, refreshKey, resId, page]);

  //this snippet fetching orders from page 1 to n
  useEffect(() => {
    const fetchAllOrders = async () => {
      try {
        setLoading(true);
        let allOrders = []; 
        for (let i = 1; i <= page; i++) {
          const response = await axios.get(`spadeleaf-api.php?action=getOrders&status=${category}&page=${i}`);
          const fetchedOrders = response.data;
          
          if (fetchedOrders.length === 0) {
            setHasMore(false);
            break;
          }
          else {
            setHasMore(true);
          }
          
          allOrders = [...allOrders, ...fetchedOrders];
        }
  
        const uniqueOrders = allOrders.filter(
          (order, index, self) => index === self.findIndex(o => o.OrderId === order.OrderId)
        );
  
        setOrderItems(uniqueOrders);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Failed to fetch food items. Please try again later.");
        setLoading(false);
      }
    };
  
    fetchAllOrders();

    const interval = setInterval(() => fetchAllOrders(page), 60000);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [category, refreshKey, page]); 

  // Infinite scroll handler
  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 500 && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1); // Load more orders when reaching bottom
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  //clear previous order on category change
  useEffect(() => {
    setOrderItems([]);
    setPage(1);         
    setHasMore(true);   
    setLoading(true);  
  }, [category]);


  if (loading && page === 1) {
    return <div>Loading...</div>; // or render a loading spinner
  }

  if (error) {
    return <div>{error}</div>; // or render an error message
  }

  return (
    <div className="orderItemContainer">
      {orderItems.map(orderItem => (
        <div key={orderItem.OrderId} className="orderContent">
          <input
            type="checkbox"
            checked={selectedOrders.includes(orderItem.OrderId)}
            onChange={() => handleOrderSelection(orderItem.OrderId, orderItem.TableNumber)}
            disabled={
              orderItem.OrderStatus !== "2" || 
              orderItem.TableNumber === "0" || 
              (selectedTableNumber && orderItem.TableNumber !== selectedTableNumber)
            }
          />
          <div className="customerDetails">
            <h2>{orderItem.CustomerName}</h2>
            {orderItem.CustomerPhone !== null && <h5>Phone: {orderItem.CustomerPhone}</h5>}
            {orderItem.TableNumber !== "0" && <h4>Table: {orderItem.TableNumber}</h4>}
          </div>
          <div className="orderDetails">
            <table className="orderTable">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {orderItem.OrderItems.map((item) => {
                  const itemName = JSON.parse(JSON.stringify(item.MenuItem.ItemName));
                  const quantity = JSON.parse(JSON.stringify(item.Quantity));
                  const amount = JSON.parse(JSON.stringify(item.Amount));
                  return (
                    <tr key={itemName}>
                      <td>{itemName}</td>
                      <td>x{quantity}</td>
                      <td>₹{amount}</td>
                    </tr>
                  );
                })}
                { orderItem.RestaurantServiceCharge &&
                <tr>
                  <td>Service Charges</td>
                  <td>{orderItem.RestaurantServiceCharge}%</td>
                  <td>₹{orderItem.OrderItems.reduce((acc, item) => acc + item.Amount * (orderItem.RestaurantServiceCharge / 100), 0)}</td>
                </tr>
                }
                { orderItem.RestaurantTax &&
                <tr>
                  <td>Taxes</td>
                  <td>{orderItem.RestaurantTax}%</td>
                  <td>₹{orderItem.OrderItems.reduce((acc, item) => acc + item.Amount * (orderItem.RestaurantTax / 100), 0)}</td>
                </tr>
                }
                <tr>
                  <td><b>Total</b></td>
                  <td></td>
                  <td><b>₹{(parseFloat(orderItem.TotalPrice))}</b></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="orderStatus">
            {fetchOrderStatus(orderItem)}
          </div>
        </div>
      ))}
      {selectedOrders.length > 1 && (
        <div className="mergeSection">
          <h4>Merged Order Summary</h4>
          <PDFDownloadLink document={generateMergedBillPdf(mergeSelectedOrders())} fileName="merged_bill.pdf">
            <button className="billButton">Generate Merged Bill</button>
          </PDFDownloadLink>
        </div>
      )}
      {loading && <div>Loading more orders...</div>}
      {!hasMore && <p>No more orders to load</p>}
    </div>
  );
}

export default OrderItem;
