import React, {useState, useEffect, useContext} from "react";
import axios from "../services/axios";

import "./MenuItem.css";

import ItemInsertModal from "./ItemInsertModal";

function MenuItem( { category } ) {
    const[foodItems, setFoodItems] = useState( [] );
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    async function fetchData() {
        try {
            const response = await axios(`spadeleaf-api.php?action=getFoodItem&ItemCategory=${category}`);
            setFoodItems(response.data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            setError("Failed to fetch food items. Please try again later.");
            setLoading(false);
          }
    }

    const handleItemChange = (itemId, propertyName, updatedValue) => {
        async function updateItem() {
            try {
                const response = await axios.post("spadeleaf-api.php?action=updateFoodItem", {
                    item: itemId,
                    property: propertyName,
                    value: updatedValue,
                  });
                if (response.data.message === 1)
                {
                    alert("Updated");
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);
                }
              } catch (error) {
                console.log(error);
              }
        }
        updateItem();
    }

    const handleToggleVisibility = async (itemId) => {
        try {
            const response = await axios.post("spadeleaf-api.php?action=hideFoodItem", {
                itemId: itemId,
            });
            if (response.data.message === 1) {
                fetchData();
            } else {
                // alert(response.data.message);
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteItem = async (itemId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this item?");
        
        if (isConfirmed) {
            try {
                const response = await axios.post('spadeleaf-api.php?action=deleteFoodItem', { 
                    itemId: itemId,
                    categoryId: category, 
                });
                if (response.data.message === 1)
                {
                    fetchData();
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);  
                }
            } catch (error) {
                console.error('Error deleting item:', error);
            }
        } else {
            console.log("Item deletion canceled.");
        }
    };

    useEffect(() =>{
        fetchData();
    }, [category, handleItemChange, handleDeleteItem]); 

    if (loading) {
        return <div>Loading...</div>; // or render a loading spinner
    }
    
    if (error) {
        return <div>{error}</div>; // or render an error message
    }

    return (
        <div className="menuItemContainer">

            <ItemInsertModal isOpen={isModalOpen} onClose={closeModal} category={category}/>

            <button className="addItemButton" onClick={openModal}>Add food</button>

            {foodItems.map((foodItem) => (
                <div
                  key={foodItem.ItemId}
                  className={`menuItem`}
                >
                    <div className={`menuImageContainer ${foodItem.IsHidden === '1' ? 'hidden' : ''}`}>
                        <img
                            className="menuImage"
                            src={foodItem.ItemImage} 
                            alt={foodItem.ItemName}
                        />
                    </div>
                    <div className="menuItemDescription">
                        <h2>{foodItem.ItemName}</h2>
                        <div className={`priceContainer ${foodItem.IsHidden === '1' ? 'hidden' : ''}`}>
                            <h5>Price: ₹</h5>
                            <h5
                                contentEditable="true"
                                onBlur={(e) => {
                                    const updatedValue = e.target.innerText;
                                    handleItemChange(foodItem.ItemId, "ItemPrice", updatedValue);
                                }}
                            >
                                {foodItem.ItemPrice}
                            </h5>
                        </div>
                        <p
                            className={`${foodItem.IsHidden === '1' ? 'hidden' : ''}`}
                            contentEditable="true"
                            onBlur={(e) => {
                                const updatedValue = e.target.innerText;
                                handleItemChange(foodItem.ItemId, "ItemDescription", updatedValue);
                            }}
                        >
                            {foodItem.ItemDescription}
                        </p>
                        <div className="actionButtons">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={foodItem.IsHidden !== '1'}
                                    onChange={() => handleToggleVisibility(foodItem.ItemId)}
                                />
                                <span className="slider"></span>
                            </label>
                            <button
                                className="deleteButton"
                                onClick={() => handleDeleteItem(foodItem.ItemId)}
                            >
                                Delete
                            </button>
                        </div>                                         
                    </div>
                </div>
            ))}

        </div>
    );
  }
  
  export default MenuItem;