import React, { useContext, useState } from 'react';
import { FaUser, FaLock } from "react-icons/fa";

import axios from "../services/axios";
import { UserContext } from '../contexts/userContext';

import './SignupLogin.css';

function SignupLogin() {
    const [loginEmail , setLoginEmail] = useState('');
    const [loginPass , setLoginPass] = useState('');
    const {setResId} = useContext(UserContext);

    // function to update state of input with
    // values entered by user in form
    const handleLoginEmail =(e)=>{
        setLoginEmail(e.target.value);
      }

    const handleLoginPass =(e)=>{
        setLoginPass(e.target.value);
    }

    const handleLogin = () => {
        if (!loginEmail || !loginPass)
        {
            alert("Please fill all the details!");
        }
        else {
            async function fetchData() {
                try {
                    const response = await axios.post("spadeleaf-api.php?action=resLogin", {
                        email: loginEmail,
                        pass: loginPass,
                      });
                    if (response.data.message === 1)
                    {
                        //save user in local storage 
                        localStorage.setItem('accessToken', response.data.accessToken);
                        localStorage.setItem('refreshToken', response.data.refreshToken);
                        localStorage.setItem('resName', response.data.resName);
                        localStorage.setItem('resGST', response.data.resGST);
                        setResId(response.data.accessToken);
                    }
                    else
                    {
                        alert(response.data.message);
                        console.log(response);
                    }
                  } catch (error) {
                    console.log(error);
                  }
            }
            fetchData();
        }
    }

    return (
        <div className='wrapper'>
            <div>
                <h1>Login</h1>
                <div className='input-box'>
                    <input type='email' placeholder='Email' required value={loginEmail} onChange={(e) => { handleLoginEmail(e); } }/>
                    <FaUser className='icon'/>
                </div>
                <div className='input-box'>
                    <input type='password' placeholder='Password' required value={loginPass} onChange={(e) => { handleLoginPass(e); } }/>
                    <FaLock className='icon'/>
                </div>

                <div className='remember-forgot'>
                    <label><input type='checkbox' checked/>Remember me</label>
                    <a href='#'>Forgot password?</a>
                </div>

                <button onClick={() => handleLogin()} >Login</button>
            </div>
        </div>
    );
}

export default SignupLogin;