import React, { useState, createContext } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [resId, setResId] = useState();
  const [resName, setResName] = useState();
  const [resGST, setResGST] = useState();

  return (
    <UserContext.Provider
      value={{
       resId,
       setResId,
       resName,
       setResName,
       resGST,
       setResGST,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};