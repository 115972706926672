import React, {useState, useContext} from "react";
import "./CategoryInsertModal.css";

import axios from "../services/axios";

function CategoryInsertModal({ isOpen, onClose }) {
  const [category, setCategory] = useState('');

  const handleCategoryChange =(e)=>{
    setCategory(e.target.value);
  }

  const handleClick = () => {
    if (!category)
    {
        alert("Please enter a category name!");
    }
    else {
        async function fetchData() {
            try {
              //api function yet to be made
                const response = await axios.post("spadeleaf-api.php?action=addFoodCategory", {
                   itemCategory: category,
                  });
                if (response.data.message === 1)
                {
                    alert("Category is added!");
                    window.location.reload();
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);
                }
              } catch (error) {
                console.log(error);
              }
        }
        fetchData();
    }
}

  return (
    <>
    {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn" onClick={onClose}>
            &times;
            </span>
            <h2 className="modalTitle">Add a new food category</h2>
            <div className="form">
                    <form>
                        <label>
                        Category:
                        </label><br/>
                        <input type="text" value={category} required onChange={(e)=> {handleCategoryChange(e)}} /><br/>
                    </form>
                </div>
                <div className="buttons">
                    <button className="button" onClick={() => handleClick()}>Insert category</button>
                </div>
        </div>
        </div>
    )}
    </>
  )
}

export default CategoryInsertModal;