import React, {useEffect, useContext} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';

import { UserContext } from './contexts/userContext';

import NavBar from './components/NavBar';
import OrderPage from "./components/body/OrderPage";
import MenuPage from "./components/body/MenuPage";
import About from "./components/body/About";
import Contact from "./components/body/Contact";
import SignupLogin from "./components/SignupLogin";

function App() {
  const { resId, setResId } = useContext(UserContext);
  const { resGST, setResGST } = useContext(UserContext);

  useEffect(() => {
    if (!resId) {
      const loggedInUser = localStorage.getItem("accessToken");
      if (loggedInUser) {
        setResId(loggedInUser);
      }
    }
    if (!resGST) {
      const gst = localStorage.getItem("resGST");
      if (gst) {
        setResGST(gst);
      }
    }
  }, [resGST, resId, setResGST, setResId]);

  if (resId) {
    return (
      <div className="App">
        <Router>
            <NavBar />
            <div className="body-container">
              <Routes>
                <Route path="/" Component={OrderPage} />
                <Route path="/menu" Component={MenuPage} />
                <Route path="/about" Component={About} />
                <Route path="/contact" Component={Contact} />
              </Routes>
            </div>
          </Router>
      </div>
    );
  } else {
    return (
      <div className='signupLogin'>
        <SignupLogin />
      </div>
    );
  }
}

export default App;
