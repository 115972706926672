import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Welcome to Spadeleaf, the Future of Digital Menus!
      </p>
      <p>
        At Spadeleaf, we’re passionate about revolutionizing the dining experience through our cutting-edge QR menu system. We understand that you’re already utilizing a QR menu system, and that’s a fantastic step towards modernizing your operations. However, we believe that our system can elevate your business even further by addressing some common pain points and offering unique advantages.
      </p>
      <h2>Why Switch to Spadeleaf?</h2>
      <ul>
        <li><strong>Enhanced Control & Flexibility</strong>: Unlike some systems where updates can be cumbersome and slow, our platform ensures that changes to your menu, item availability, and pricing are reflected instantly across all devices. Say goodbye to delays and syncing issues! Enjoy complete control over your menu with user-friendly tools that make managing your offerings a breeze.</li>
        <li><strong>Superior User Experience</strong>: Our system is designed to be intuitive for both your staff and customers. A streamlined interface means faster adoption, fewer complaints, and a smoother experience all around. Customers can easily customize their orders, split the bill, and even pay directly through the app, enhancing convenience for everyone.</li>
        <li><strong>Powerful Insights & Analytics</strong>: With our comprehensive analytics dashboard, you can track the popularity of menu items, identify peak times, and analyze the impact of menu changes on sales. Use these insights to make data-driven decisions that drive revenue. Real-time data helps you fine-tune your menu and pricing strategies to maximize profitability.</li>
        <li><strong>Cost-Effective Solutions</strong>: We offer competitive pricing with no hidden fees. Our straightforward setup ensures that you’re up and running in no time, with a clear understanding of costs. Experience better value and a higher return on investment with features designed to enhance both your operational efficiency and customer satisfaction.</li>
        <li><strong>Addressing Common Pain Points</strong>: If your current system isn’t fully embraced by your customers, our user-friendly design and enhanced features are likely to boost engagement and satisfaction. Forget about the hassle of contacting support for updates. Our system allows for quick and easy modifications, ensuring your menu is always up-to-date.</li>
        <li><strong>Seamless Transition</strong>: We can implement our system without any downtime. Your existing QR system will remain operational while we integrate ours, ensuring a smooth transition. We assist with transferring your existing data—menu items, pricing, and more—so you don’t have to start from scratch.</li>
        <li><strong>Try Before You Commit</strong>: We invite you to experience our system firsthand with a free one-month trial. Compare it side-by-side with your current system and see the difference for yourself, with no upfront commitment.</li>
        <li><strong>Proven Success</strong>: Don’t just take our word for it. Discover how other restaurants have benefited from making the switch. Our case studies and testimonials showcase real success stories and tangible results.</li>
        <li><strong>Future-Proof Your Business</strong>: Our system is built to grow with you. Whether you’re expanding to new locations, introducing loyalty programs, or integrating delivery options, our platform can adapt to your evolving needs.</li>
      </ul>
      <p>
        We’re excited to help you take your restaurant to the next level with our innovative QR menu system. Thank you for considering Spadeleaf—we look forward to the opportunity to work with you and enhance your dining experience.
      </p>
    </div>
  );
}

export default About;
