import React, { useState } from "react";
import "./ItemInsertModal.css";
import axios from "../services/axios";

function ItemInsertModal({ isOpen, onClose, category }) {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false); // Add loading state

    const handleNameChange = (e) => setName(e.target.value);
    const handlePriceChange = (e) => setPrice(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handleImageChange = (e) => setImage(e.target.files[0]);

    const handleClick = () => {
        if (!name || !price || !description || !image) {
            alert("Some value(s) are missing!");
        } else {
            const formData = new FormData();
            formData.append('itemName', name);
            formData.append('itemPrice', price);
            formData.append('itemDescription', description);
            formData.append('itemCategory', category);
            formData.append('itemImage', image); // Append image file

            setLoading(true); // Start loading

            async function fetchData() {
                try {
                    const response = await axios.post("spadeleaf-api.php?action=addFoodItem", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    setLoading(false); // Stop loading

                    if (response.data.message === 1) {
                        alert("Item is added!");
                        window.location.reload();
                    } else {
                        alert(response.data.message);
                        console.log(response);
                    }
                } catch (error) {
                    setLoading(false); // Stop loading even if there's an error
                    console.log(error);
                }
            }
            fetchData();
        }
    }

    return (
        <>
            {isOpen && (
                <div className="modal-overlay" onClick={onClose}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-btn" onClick={onClose}>
                            &times;
                        </span>
                        <h2 className="modalTitle">Add a new food item</h2>
                        <div className="form">
                            <form>
                                <label>
                                    Name:
                                </label><br />
                                <input
                                    type="text"
                                    value={name}
                                    required
                                    onChange={handleNameChange}
                                    disabled={loading} // Disable input while loading
                                /><br />

                                <label>
                                    Price:
                                </label><br />
                                <input
                                    type="number"
                                    min="1"
                                    step="any"
                                    value={price}
                                    required
                                    onChange={handlePriceChange}
                                    disabled={loading} // Disable input while loading
                                /><br />

                                <label>
                                    Item Description:
                                </label><br />
                                <textarea
                                    rows={5}
                                    cols={40}
                                    wrap="soft"
                                    value={description}
                                    required
                                    onChange={handleDescriptionChange}
                                    disabled={loading} // Disable input while loading
                                /><br />

                                <label>Item Image:</label><br />
                                <input
                                    type="file"
                                    accept="image/*"
                                    required
                                    onChange={handleImageChange}
                                    disabled={loading} // Disable input while loading
                                /><br />
                            </form>
                        </div>
                        <div className="buttons">
                            {loading ? (
                                <div className="loader">Inserting...</div> // Display loader
                            ) : (
                                <button className="button" onClick={handleClick}>
                                    Insert item
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ItemInsertModal;